import validateNL from 'vee-validate/dist/locale/nl.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    hello: 'hallo',
    eur: '€',
    share: 'aandeel | aandelen',
    dividend: 'dividend | dividends',
    myDividend: 'Mijn dividend | Mijn dividend',
    myInterestRate: 'Mijn rente',
    prospectus: 'prospectus',
    investment: 'investering | investeringen',
    fund: 'fonds | fondsen',
    invest: 'investeer',
    investIn: 'investeer in dit fonds',
    investMore: 'meer investeren',
    investNow: 'investeer nu',
    amount: 'hoeveelheid',
    payment: 'betaling | betalingen',
    name: 'naam | namen',
    firstName: 'Voornaam',
    surname: 'Achternaam',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    password: 'wachtwoord | wachtwoorden',
    save: 'opslaan',
    tier: 'tier',
    confirm: 'bevestig',
    activate: 'activeren',
    eIDAS: 'eIDAS',
    gDPR: 'GDPR',
    iDIN: 'iDIN',
    and: 'en',
    dashboard: 'dashboard',
    myDashboard: 'Mijn dashboard',
    back: 'terug',
    sold: 'verkocht',
    minimalInvestment: 'minimale investering',
    participation: 'participatie | participaties',
    transactionSize: 'transactiegrootte',
    available: 'beschikbaar',
    download: 'download | downloads',
    brochure: 'brochure',
    identification: 'identificatie',
    summary: 'samenvatting',
    process: 'start | processen',
    processing: 'processing',
    error: 'error',
    success: 'gelukt',
    paid: 'betaald',
    fail: 'fout',
    failed: 'mislukt',
    canceled: 'geannuleerd',
    cancel: 'annuleren',
    expired: 'verlopen',
    open: 'open',
    step: 'stap | stappen',
    fullName: 'volledige naam',
    telephone: 'telefoon',
    companyName: 'bedrijfsnaam',
    phone: 'telefoon',
    dateOfBirth: 'geboortedatum',
    bankAccount: 'bankrekening',
    sendDataChangeRequest: 'Verstuur data-wijzigings verzoek',
    dataChangeRequestPending: 'Data wijzigings verzoek is ingediend',
    dataChangeRequestValue: 'Aangevraagde waarde',
    iban: 'IBAN',
    city: 'plaats',
    country: 'land',
    address: 'adres',
    houseNumber: 'huisnummer',
    number: 'nummer',
    postalCode: 'postcode',
    welcome: 'welkom Dhr./Mevr.',
    availability: 'beschikbaarheid',
    euro: 'euro | euros',
    euroSymbol: '€',
    contact: 'contact',
    countryOfBirth: 'land van herkomst',
    additionalInfo: 'overige info',
    or: 'of',
    from: 'vanaf',
    to: 'naar',
    for: 'voor',
    support: 'support',
    subject: 'onderwerp',
    message: 'bericht',
    login: 'login',
    kycStep: 'Voer gegevens',
    register: 'registreer',
    reset: 'reset',
    resetPass: 'reset wachtwoord',
    setPass: 'kies uw wachtwoord',
    secondFactor: '2-factor authenticatie',
    revertSecondFactor: '2-factor authenticatie annuleren',
    nationality: 'nationaliteit',
    realEstate: 'vastgoed index',
    aex: 'aex',
    vs: 'vs',
    property: 'property',
    warning: 'waarschuwing',
    document: 'document | documenten',
    location: 'locatie | locaties',
    floorplan: 'plattegrond',
    benefit: 'voordeel | voordelen',
    company: 'bedrijf | bedrijven',
    setting: 'instelling | instellingen',
    logout: 'log uit',
    help: 'help',
    optional: 'optioneel',
    source: 'bron',
    portfolio: 'portfolio',
    fundsLink: 'fond | fondsen',
    knowledge: 'kennisbank',
    glossary: 'woordenlijst',
    cookie: 'cookie | cookies',
    no: 'nee',
    yes: 'ja, dit is oke',
    loadMore: 'meer fondsen tonen',
    loadLess: 'minder fondsen tonen',
    verified: 'account geverifieerd',
    pending: 'account wordt geverifieerd',
    rejected: 'account niet geaccepteerd',
    notIdentified: 'account niet gevonden',
    year: 'jaar | jaren',
    yearInitials: 'j',
    perYear: 'per jaar',
    perYearShort: 'p/jr',
    myAccount: 'mijn account',
    my: 'mijn',
    details: 'gegevens',
    na: 'n.v.t.',
    here: 'hier',
    send: 'verstuur',
    bond: 'obligatie | obligaties',
    month: 'maand | maanden',
    date: 'datum',
    yearly: 'jaarlijks',
    monthly: 'maandelijks',
    new: 'nieuwe',
    search: 'zoeken',
    next: 'volgende',
    prev: 'vorige',
    fundCompleted: 'volgeschreven',
    comingSoon: 'binnenkort beschikbaar',
    incorrectPassword: 'verkeerd wachtwoord',
    autoLogOut: 'U bent automatisch uitgelogd als gevolg van langdurige inactiviteit',
    investor: 'investeerder | investeerders',
    cookiePolicy: 'Cookie policy',
    copyright: 'Bloqhouse 2019',
    accountDisabled: 'Uw account is gedeactiveerd.',
    contactSupport: 'Neem contact op met {nameEmail} voor ondersteuning.',
    supportQuestion: 'Waar kunnen we je mee helpen? Stel hier je vraag:',
    websiteMaintenance: 'Website gesloten voor onderhoud.',
    closeModal: 'Sluit venster',
    paidOnline: 'Online betaling',
    paidOffline: 'Offline betaling',
    dutch: 'Nederlands',
    english: 'Engels',
    gender: 'aanhef',
    male: 'meneer',
    female: 'mevrouw',
    bannerLegal: 'Banner legal',
    decline: 'Weigeren',
    accept: 'Accepteer',
    disabled: 'Deze selectie kan niet worden gewijzigd',
    more: 'meer',
    endedOn: 'Beëindigd op',
  },
  validations: {
    ...validateNL.messages,
    alpha_num: 'Dit veld mag alleen letters en nummers bevatten',
    alpha_spaces: 'Dit veld mag alleen letters en spaties bevatten',
    confirmed: 'Dit veld komt niet overeen met eerder gegeven input',
    email: 'Dit veld moet een geldig e-mailadres bevatten',
    integer: 'Dit veld moet moet een nummer zijn',
    max: 'Dit veld mag niet groter zijn dan {length} karakters',
    min: 'Dit veld moet minimaal {length} karakters zijn',
    ext: 'Bestand moet van het type {types} zijn',
    required: 'Dit veld is verplicht',
    multiplier: {
      payment: 'Het bedrag van de inleg moet een meervoud zijn van {number}€',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      accessibleAndUnderstandable: 'Snel en toegankelijk investeren in vastgoed',
      applyNowInvestor: 'Registreer nu en wordt vastgoedinvesteerder!',
      exploreProperties: 'Bekijk het vastgoed',
      registerNow: 'Registreer nu',
      explorePropertiesBtn: 'Begin met verkennen',
    },
    accessible: {
      accessibleAndTransparent: 'Toegankelijk en transparant',
      weMakeItVeryEasy: 'Het is de missie van Bloqhouse om investeren in vastgoed transparant en toegankelijk te maken',
      hasManyYears: 'Gedeeld eigendom in vastgoed maakt het mogelijk te investeren zonder daarmee tijd en moeite in beheer te steken',
      youCanStartBuying: 'Na het kiezen van één van de objecten op het platform kan je binnen enkele minuten investeren',
      onceTheSaleIsCompleted: `Jouw identiteit wordt geverifieerd via iDIN en notes zijn te koop vanaf €100 tot €100.000 via iDEAL
        (notes boven €100.000 gaan via SEPA banktransactie)`,
      startBuying: 'Koop vastgoed notes vanaf €100',
      receivedMonth: 'Ontvang dividend per maand in Euro’s',
      tradeYourShares: 'Verkoop je notes via het handelsplatform (lancering eind 2019)',
    },
    fund: {
      emissionPrice: 'Prijs per aandeel',
      totalFundSize: 'Totale fonds grootte',
      seeDetails: 'Meer info',
    },
    counters: {
      emissionCosts: 'Emissie kosten',
      grossDividendYield: 'Bruto rente',
      netDividendYield: 'Rente',
      dividendsPerYear: 'Dividend per jaar',
      occupancyRate: 'Bezettingsgraad',
    },
    benefits: {
      exploreProperties: 'Bekijk het vastgoed',
      tab1: 'Investeer direct',
      tab1Title: 'Investeer direct',
      tab1Text: `Kies een object en bepaal hoeveel je wilt investeren. Met gemiddeld een extra object per week,
        streeft Bloqify ernaar om het grootste vastgoed aanbod van de Benelux te faciliteren. Je kan een eigen
        portfolio samenstellen die voldoet aan jouw criteria. Daarnaast is het makkelijk om te diversifiëren met
        verschillende soorten vastgoed. Er zijn eenmalige kosten van 2% verbonden aan een investering`,
      tab2: 'Ontvang dividend',
      tab2Title: 'Ontvang dividend',
      tab2Text: `Elk kwartaal ontvang jij dividend op jouw investering. Het dividend is de huurinkomsten minus
        de beheerkosten van het vastgoed`,
      tab3: 'Check je rente',
      tab3Title: 'Check je rente',
      tab3Text: `Check in jouw dashboard de prestatie van je investeringen. Al onze woningen worden elke 12 maanden
        door een RICS gecertificeerde taxateur gewaardeerd. Hierdoor kan jij een inschatting maken van de meerwaarde
        op je investering. De dividendinkomsten worden automatisch meegerekend in het dashboard`,
      tab4: 'Liquiditeit',
      tab4Title: 'Liquiditeit',
      tab4Text1: `Het is mogelijk notes op elk moment te verkopen via onze secundaire markt. Verkopen is simpel.
        Zet je notes te koop via het handelsplatform. Of als alternatief; wacht voor de 5-jaarlijkse verkoop van het vastgoed,
        waarbij investeerders de overwaarde van het vastgoed zullen ontvangen. De gemiddelde tijd om een aandeel te
        verkopen is 4 dagen`,
      tab4Text2: `Afhankelijk van de gekozen prijs kan het langer of korter duren. Let op: gedurende de introductieperiode
        van de secundaire markt zal het handelsvolume lager zijn dan gemiddeld, dit kan resulteren in een langere verkooptijd`,
      tab5: 'Overhead',
      tab5Title: 'Overhead',
      tab5Text: `Investering in vastgoed is veel werk. Bloqify verandert dat. Papierwerk, advocaten, wanbetalers en reparateurs
        zijn verleden tijd. Bloqify met haar aangesloten vastgoedfondsen regelen dat voor jou, met vele decennia marktervaring.
        Zodat jij kan profiteren van huurinkomsten en waardestijgingen in vastgoed, zonder het gedoe!`,
      tab5TextPoint1: 'Investeringen zoeken',
      tab5TextPoint2: 'Onderhandelingsproces',
      tab5TextPoint3: 'Papierwerk en advocaten',
      tab5TextPoint4: 'Huurders zoeken',
      tab5TextPoint5: 'Onderhoud en reparaties',
      tab5TextPoint6: 'Taxaties',
      tab5TextPoint7: 'Verkoopproces',
      tab6: 'Ons beleid',
      tab6Title: 'Ons beleid',
      tab6Satisfied: 'Tevreden huurders:',
      tab6Text1: `Bloqify gelooft dat tevreden huurders de sleutel is tot toekomstig succes. Onze vastgoedfondsen vragen
        eerlijke huurprijzen en onderhouden het vastgoed grondig, zodat huurders voor langere periodes blijven. Dit betekent
        voor jou stabiele huurinkomsten`,
      tab6SmartPurchases: 'Slimme aankopen:',
      tab6Text2: `Bloqify werkt samen met grote vastgoedfondsen in Nederland. Hierdoor vinden we de beste aankopen, die
        normaal gezien vaak in bezit zijn van grote bedrijven of rijke individuen`,
    },
    investing: {
      investingMadeSimple: 'Simpel investeren',
      selectTheFund: 'Selecteer het fonds waarin je wilt investeren',
      registerAndIdentify: 'Registreer en identificeer via iDIN',
      buyYourShares: 'Koop je notes via iDEAL of SEPA',
      receiveMonth: 'Ontvang dividend per maand',
    },
    stock: {
      amsterdamStockExchange: 'Amsterdam Exchange Index',
      pastPerformance: 'In het verleden behaalde resultaten vormen geen garantie voor de toekomst',
    },
    safeGuards: {
      investmentSafeguards: 'Investerings zekerheden',
      weTakeOurResponsabilities: 'We nemen onze verantwoordelijkheden om je veilig en zonder stress te laten investeren',
      yourInvestmentsAreRingFenced: 'Jouw investeringen zijn veilig',
      andAreSeparated: 'Afgeschermd en gescheiden van de activa en passiva van Bloqhouse',
      compensationScheme: 'Verzekerd',
      protectsTheFunds: 'Jouw investeringen zijn verzekerd via een Stichting Derdengelden',
      financialConductAuthority: 'Autoriteit Financiële Markten',
      isTheBody: 'Het platform is gereguleerd door de Autoriteit Financiële Markten',
    },
    expectations: {
      assetsUnderManagement: 'Vermogen onder beheer',
      capitalReturnedToInvestors: 'Totaal uitgekeerd aan investeerders',
      rentalIncome: 'Huurinkomsten uitgekeerd aan investeerders',
    },
    faq: {
      howDoIIdentify: 'Hoe identificeer ik mijzelf op het platform?',
      forDigital: `Voor het digitaal identificeren van onze private beleggers gebruiken we iDIN,
        een toepassing ontwikkeld door de Nederlandse banken`,
      howIGetDividends: 'Hoe krijg ik mijn dividend uitgekeerd?',
      theDvidendsare: 'Het dividend op het Bloqhouse platform wordt uitgekeerd in de vorm van Euro’s die gestort worden op jouw rekening',
      howCanISell: 'Hoe kan ik mijn investering verkopen?',
      youCanIn2Ways: `Dit kan op twee manieren. (1) Via het handelsplatform kan je ten alle tijde jouw investering te koop zetten.
        (2) Na de looptijd van het fonds wordt de waarde van het vastgoed naar rato verdeeld over de aandeelhouders op dat moment`,
      whenIsItSold: 'Wanneer wordt het vastgoedproject verkocht / fonds geliquideerd?',
      theAverage: 'De gemiddelde looptijd van een fonds is 5 jaar',
      isMyInvestmentInsured: 'Is mijn investering verzekerd tegen ongevallen?',
      yesAllFundsAre: `Ja, al de fondsen van onze aangesloten bedrijven zijn volledig verzekerd,
        waardoor je als investeerder hier geen risico voor loopt`,
      howDoIPay: 'Hoe betaal ik mijn notes?',
      sharesCanBe: 'Notes kunnen gekocht worden via iDEAL. Binnenkort zijn ook Bitcoin en PayPal mogelijk',
      whatHappens: 'Wat gebeurt er met mijn investering als het fonds niet wordt volgeschreven?',
      inThatCase: 'In dat geval wordt jouw investering direct teruggestort op jouw bankrekening',
      upUntillWhichAmount: 'Tot hoeveel Euro kan ik direct online via iDEAL afrekenen?',
      till100k: 'Tot €100.000 kan je direct online afrekenen, anders zal er via SEPA een bank transfer gemaakt moeten worden',
      whoDecides: 'Wie maakt de beslissingen aangaande het fonds en vastgoed beheer?',
      thatIsBeingCared: 'Dat doen de fondsbeheerders',
      questionsAnswers: 'Vragen',
    },
    risks: {
      investmentRisks: 'Let op',
      generalRisksAssociated: `Er zijn algemene risico’s verbonden aan het beleggen in vastgoedfondsen: het marktrisico, risico met betrekking tot
        de vergunningsvereisten, risico stockdividend, rendementsrisico, financierings- en renterisico, verhuur- en leegstandsrisico,
        fiscaal risico, inflatierisico en risico’s samenhangend met de beheerder`,
      theMainRisks: 'Bloqhouse is niet verplicht tot het inkopen van notes',
      aDetailedDescription: 'Een uitgebreide beschrijving van de risico’s is te vinden in het prospectus van het gerelateerde fonds',
    },
    cookies: {
      allowUs: 'Staat u ons toe om {cookiesLink} op te slaan?',
      yourInfoAnalytics: 'Uw informatie zal worden gebruikt voor analyse en website-optimalisatie',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Wachtwoord vergeten?',
      dontHaveAnAccountYet: 'Nog geen account?',
      accessYourBloqify: 'Bloqify dashboard',
      enterYourPassword: 'Vul uw wachtwoord in',
      pleaseActivate: 'We hebben je een e-mailbericht gestuurd met daarin een link om jouw account te activeren',
      userHasRole: 'U heeft geprobeerd in te loggen met een admin account. Gelieve alleen te gebruiken binnen de Bloqadmin omgeving.',
      captchaTimeExpired: 'Captcha verificatie mislukt',
      tryAgain: 'Probeer nogmaals',
    },
    register: {
      becomeAnInvestor: '',
      atLeast10Chars: 'Minimaal 10 tekens',
      alreadyHaveAnAccount: 'Al een account?',
      thankYou: 'Bedankt voor je registratie',
      pleaseCheckEmail: 'Wij hebben een e-mailbericht naar je verzonden om je account te activeren',
      itCanTake: 'Dit kan een paar minuten duren',
      youCan: '',
      afterActivating: 'na activatie van je account',
      confirmPassword: 'Bevestig wachtwoord',
    },
    reset: {
      getAPassword: 'Stuur mij een wachtwoord reset link',
      sendResetLink: 'Stuur reset link',
      rememberYourPassword: 'Wachtwoord onthouden?',
      emailSent: 'Als het ingevoerde emailadres bij ons bekend is, heeft u een e-mail ontvangen',
      pleaseCheckInbox: 'Klik op de link in de e-mail om je wachtwoord te resetten',
      expiredError: 'Uit veiligheidsoverwegingen was deze link maar 24 uur geldig. Vul hier uw e-mailadres in om een nieuwe e-mail te versturen, volg die link en maak het wachtwoord aan.',
    },
    verification: {
      emailVerification: 'E-mail verificatie',
      resetPassword: 'Reset wachtwoord',
      claimPasswoord: 'Claim uw wachtwoord',
      changeEmail: 'Verandering e-mail',
      verifyingSuccess: 'Alles is ingesteld, je kunt nu inloggen',
      verifyingError: 'Er is iets fout gegaan! Registreer opnieuw',
      changeError: 'Er is iets fout gegaan! Probeer het opnieuw',
      changeSuccess: 'Alles is ingesteld, je kunt nu inloggen',
      passwordLenght: 'Wachtwoord moet 10 of langer zijn',
      changeDataRequestConfirmation: 'Weet je zeker dat je je <strong>{type}</strong> van <em>{oldValue}</em> naar <em>{newValue}</em> wilt veranderen',
      revertSecondFactor: 'U heeft 2-factor authenticatie geactiveerd, op deze pagina kunt u dit ongedaan maken',
      revertSecondFactorSuccess: 'U heeft 2-factor authenticatie succesvol gedeactiveerd',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verifeer',
      dismiss: 'Afwijzen',
      notNow: 'Niet nu',
      pending: 'Jouw account wordt geverifieerd',
      pendingDescription: 'U krijgt spoedig bericht van {fundManager}',
      youCanInvest: 'Je kan wel alvast investeren in je eerste fonds',
      youNeedApproval: 'Pas na verificatie van jouw account kan je beleggen',
      upgradeTo: 'Je moet jouw account nog verifiëren',
      basicUser: 'Je hebt nu alleen nog een account aangemaakt',
    },
    balance: {
      totalBalance: 'Mijn deelnemingen',
      onTheseFunds: 'Geïnvesteerd in',
      annualStatement: 'Jaaroverzicht',
    },
    dividends: {
      onTheseFunds: 'Ontvangen uit',
      comingSoon: 'Binnenkort worden hier uw ontvangen rentes getoond',
    },
    funds: {
      onBoard: 'In portfolio',
      notOnBoard: 'Niet in portfolio',
      totalValue: 'Fondsomvang',
      dealSize: 'Totale leningsom',
      numberOfDeals: 'Aantal participaties',
      myInvestment: 'Mijn investering',
      netDividendYield: 'Rente',
      netDividendYieldMine: 'Mijn rendement',
      startToInvest: 'Start met beleggen in vastgoed',
      investInOtherFunds: 'Investeer ook in rest van de fondsen om meer dividend te ontvangen',
      investMoreOnSingleFund: 'Bekijk het vastgoed aanbod',
      showAlltheFunds: 'Toon alle fondsen',
      myPortfolio: 'Mijn Portfolio',
      myFunds: 'Mijn fondsen',
      otherFunds: 'Beschikbare fondsen',
      myFundsPlaceholder: 'Hier worden de fondsen getoond waarin je hebt geïnvesteerd',
      myFundsStartInvest: 'Investeer in jouw eerste fonds',
      progress: 'Fonds vooruitgang',
      more: 'meer',
      premium: 'Dit is een Besloten fonds, login om deze sectie te zien',
      premiumLabel: 'Besloten fondsen',
      premiumFund: 'Besloten fonds',
    },
    investments: {
      showPayments: 'Toon transacties',
      hidePayments: 'Verberg transacties',
      loadAllInvestments: 'Alle investeringen',
      noPayments: 'Er zijn nog geen transacties',
      requestSupport: 'Neem contact met ons op',
      showFewerPayments: 'Minder transacties',
      showAllPayments: 'Meer transacties',
      active: 'actief',
      ended: 'uitgestapt',
      started: 'begonnen',
    },
    support: {
      technical: 'Technisch',
      technicalQuestion: 'Technische vraag',
      fundSpecificQuestion: 'Fonds specifieke vraag',
      aboutTheFund: 'Over het fonds',
      sendMessage: 'Verzenden',
      messageSent: 'Je bericht is verzonden',
      youWillBeAnswered: 'Wij zullen je z.s.m. per e-mailbericht beantwoorden',
    },
    errors: {
      pdfError: 'Er is een fout opgetreden bij het ophalen van het document. Probeer het later opnieuw of neem contact op met ondersteuning',
      noInvestmentsError: 'Er zijn geen investeringen voor het geselecteerde jaar',
    },
  },
  settings: {
    details: {
      firstAndLastName: 'Voor en achternaam',
      changePassword: 'Verander wachtwoord',
      changeEmail: 'Verander e-mailadres',
      newPasswordOptional: 'Nieuw wachtwoord',
      newPasswordOptionalRepeat: 'Herhaal nieuw wachtwoord',
      confirmWithCurrentPassword: 'Bevestig met huidig wachtwoord',
      personalDetails: 'Persoonlijke gegevens',
      leaveEmpty: 'Laat leeg als je je wachtwoord niet wilt veranderen',
      useAtLeastTen: 'Gebruik ten minste 10 tekens om je account veilig te houden',
      pleaseConfirm: 'Voer eerst het oude wachtwoord in om het wachtwoord te wijzigen',
      currentPassword: 'Huidig wachtwoord',
      passwordToChangeMail: 'Typ jouw huidige wachtwoord om jouw e-mailadres te wijzigen',
      changeHere: 'Dit is het huidige bij ons bekende IBAN nummer, wijzig indien nodig en klik op opslaan',
      mailChanged: 'Uw e-mailadres is gewijzigd. Controleer uw e-mail om het nieuwe e-mailadres te activeren en opnieuw in te loggen',
      bankAccountChanged: 'Jouw IBAN is succesvol aangepast',
      passwordChanged: 'Je wachtwoord is gewijzigd. Log alsjeblieft nogmaals in',
      nameUpdated: 'Uw naam is bijgewerkt',
      linkSent: 'Open de link die we zojuist naar uw e-mail hebben gestuurd om uw wijziging van bankrekeningnummer te bevestigen',
      identity: 'Persoonlijke Informatie',
      credentials: 'Geloofsbrieven',
    },
    identification: {
      identification: 'Identificatie',
      complete: 'Verifieer account',
      support: 'Support',
      title: 'Voltooi deze stappen om volledig toegang te krijgen',
      userIdentification: 'Account aanmaken',
      registeredToPlatform: 'Je hebt een account aangemaakt',
      investorIdentification: 'Verifieer jouw account',
      completeStep: 'Voltooi deze stap en begin met investeren',
      success: 'Jouw account is geverifieerd',
      rejected: 'Jouw identificatieverzoek is afgewezen',
    },
    tier: {
      currentTier: 'Niet van toepassing',
      cannotRequestTier: 'Je kan op dit moment niet jouw tier level inzien of wijzigen',
      workingOnFunctionality: 'We werken hard aan deze functionaliteit',
    },
  },
  help: {
    baseKnowledge: {
      baseKnowledge: 'Kennisbank',
      rics: 'RICS',
      aex: 'AEX',
      afm: 'AFM',
    },
    faq: {
      faq: 'FAQs',
      isIdinSecure: 'Is iDIN veilig?',
      howPersonalInformation: 'Hoe wordt er omgegaan met mijn persoonsgegevens?',
      howInvestDutch: 'Hoe kan ik investeren als ik geen Nederlandse bankrekening heb?',
      howInvestCompany: 'Hoe kan ik investeren vanuit mijn bedrijf?',
      iDINAdheres: 'iDIN conformeert zich aan de Europese jurisdictie',
      contactOnUpdates: 'We nemen alleen contact met je op omtrent belangrijke updates over je account en investeringen',
      pleaseSend: 'Stuur alsjeblieft een',
      manualRequest: 'investerings aanvraag',
      howDoIIdentify: 'Hoe identificeer ik mijzelf op het platform?',
      forDigital: `Voor het digitaal identificeren van onze private beleggers gebruiken we iDIN,
        een toepassing ontwikkeld door de Nederlandse banken`,
      howIGetDividends: 'Hoe krijg ik mijn dividend uitgekeerd?',
      theDvidendsare: 'Het dividend op het Bloqhouse platform wordt uitgekeerd in de vorm van Euro’s die gestort worden op jouw rekening',
      howCanISell: 'Hoe kan ik mijn investering verkopen?',
      youCanIn2Ways: `Dit kan op twee manieren. (1) Via het handelsplatform kan je ten alle tijde jouw investering te koop zetten.
        (2) Na de looptijd van het fonds wordt de waarde van het vastgoed naar rato verdeeld over de aandeelhouders op dat moment`,
      whenIsItSold: 'Wanneer wordt het vastgoedproject verkocht / fonds geliquideerd?',
      theAverage: 'De gemiddelde looptijd van een fonds is 5 jaar',
      isMyInvestmentInsured: 'Is mijn investering verzekerd tegen ongevallen?',
      yesAllFundsAre: `Ja, al de fondsen van onze aangesloten bedrijven zijn volledig verzekerd,
        waardoor je als investeerder hier geen risico voor loopt`,
      howDoIPay: 'Hoe betaal ik mijn notes?',
      sharesCanBe: 'Notes kunnen gekocht worden via iDEAL. Binnenkort zijn ook Bitcoin en PayPal mogelijk',
      whatHappens: 'Wat gebeurt er met mijn investering als het fonds niet wordt volgeschreven?',
      inThatCase: 'In dat geval wordt jouw investering direct teruggestort op jouw bankrekening',
      upUntillWhichAmount: 'Tot hoeveel Euro kan ik direct online via iDEAL afrekenen?',
      till100k: 'Tot €100.000 kan je direct online afrekenen, anders zal er via SEPA een bank transfer gemaakt moeten worden',
      whoDecides: 'Wie maakt de beslissingen aangaande het fonds en vastgoed beheer?',
      thatIsBeingCared: 'Dat doen de fondsbeheerders',
    },
  },
  checkout: {
    fund: {
      selectAnAmount: 'investeringsbedrag',
      moreInfo: 'meer info',
      emissionPricePerShare: 'Prijs per aandeel',
      totalFundSize: 'Totale fonds grootte',
      emissionCosts: 'Emissie kosten',
      grossDividendYield: 'Bruto rente',
      netDividendYield: 'Rente',
      verifyYourIdentity: 'identificeer mij',
      fundDocs: 'documentatie',
      noFilesFound: 'geen docs gevonden',
    },
    investment: {
      selectTheAmount: 'Selecteer een te investeren bedrag in Euro, in stappen van {steps}',
      amountIn: 'Bedrag in',
      investmentCosts: 'Investering',
      emissionCosts: 'Emissiekosten',
      totalCosts: 'Totale investering',
      maximumInvestment: 'Maximale investering met jouw account',
      placeOrder: 'plaats order',
      startThePayment: 'start de betaling',
      legalTerms: 'Kies investeringsbedrag',
      finalize: 'Ga door naar juridische voorwaarden',
      selectAnOption: 'selecteer een optie',
      dividends: 'selecteer rente',
      availableFund: 'De voortgangsbalk geeft de beschikbaarheid van het fonds weer',
      acceptAndContinue: 'Akkoord & Doorgaan',
      questionnaire: 'En ga naar de volgende stap',
    },
    questionnaire: {
      questionnaire: 'Herkomst vermogen',
      mandatory: 'Alle velden zijn verplicht',
      errorLoadingQuestions: 'Fout bij laden van vragen',
    },
    legal: {
      accept: 'Accepteer de voorwaarden',
      continue: 'Ga door naar betaling',
      legalAgreement: 'Investeringsvoorwaarden',
      paymentRecap: 'Betalingsoverzicht',
      finalisingPayment: 'Voltooi de betaling',
      interestRate: 'Rente',
    },
    manualOrder: {
      requestForInvestment: 'Vraag investering aan',
      amountToInvestIn: 'Bedrag ter investering',
      sendRequest: 'Stuur aanvraag',
      youCanAlsoContact: 'Je kan ook contact opnemen met ons via e-mail of telefoon',
      weWillGetYouBack: 'Je wordt beantwoord binnen 48 uur',
    },
    status: {
      paymentCompleted: 'Betaling succesvol',
      paymentFailed: 'Betaling mislukt',
      paymentNotFound: 'Betaling niet gevonden',
      paymentOpen: 'Betaling wordt verwerkt',
      youBought: 'Je kocht',
      youTriedToBuy: 'Je probeerderde <strong>{shares} notes</strong> te kopen voor <strong>{euro} Euro</strong>',
      tryAgain: 'probeer nogmaals',
      accountInvestAgain: 'Bekijk andere fondsen',
      backToThePortfolio: 'Ga naar het dashboard',
      congratulations: 'Gefeliciteerd',
    },
    unlimited: {
      investMoreThan: 'Investeer meer dan',
      ourProvider: 'Onze payment provider kan momenteeel je transactie niet verwerken',
      toInvestMore: 'Om meer te investeren, stuur ons een aanvraag of betaal in meerdere stappen',
      startRequest: 'Start investerings aanvraag',
      youCanEnter: 'Verstrek ons je gegevens en het gewenste bedrag ter investering',
      managerContacts: 'Beheer contacten',
    },
    errors: {
      notEnoughPermissions: 'Onvoldoende rechten met deze referenties',
      somethingWentWrong: 'Oeps, er is iets misgegaan tijdens het identificatieproces',
      somethingIsMissing: 'Er mist iets',
      somethingIsNotWorking: 'Er gaat iets mis',
      problemPersist: 'Als het probleem blijft bestaan, neem dan contact met ons op voor technische ondersteuning',
      error404: 'Fout 404 - Pagina niet gevonden',
      backToHomepage: 'Terug naar de startpagina',
    },
    redirect: {
      text: 'De betaling wordt gegenereerd via Mollie, onze betaaldienstverlener...',
    },
  },
  identification: {
    privateInvestor: 'Natuurlijk persoon',
    selectAnOption: 'Maak een keuze',
    dutchBankAccount: 'Identificeren middels iDIN (identificatie service)',
    noDutchBankAccount: 'Identificeren middels digitaal inschrijfformulier',
    businessTitle: 'Rechtspersoon',
    businessInvestment: 'Identificeren middels digitaal inschrijfformulier',
    business: {
      fillFieldsCompany: 'Bedrijfsinformatie',
      kvkNumber: 'KvK nummer',
      uploadYourKvKImage: 'Upload je KvK uittreksel',
      submitYourData: 'Verzend gegevens',
      orContactUs: 'Contact ons',
      youCanAlso: 'Je kan ook contact opnemen met ons via e-mail of telefoon',
      weWillGetBackToYou: 'Je wordt beantwoord binnen 48 uur',
    },
    idin: {
      selectAnAmount: 'Ga door naar het bedrag kiezen',
      identityVerification: 'Identificatie',
      pleaseVerify: 'Identificeer jezelf met behulp van',
      verificationService: 'een identificatie service ondersteund door de Nederlandse banken',
      completedIdin: 'Je iDIN identificatie is voltooid',
      pleaseWait: 'Een momentje, we zoeken contact met iDIN',
      pleaseSelectAnOption: 'Selecteer een optie',
      selectBank: 'Selecteer je bank',
      thereWasAnError: 'Er is iets misgegaan met iDIN',
      pleaseContactSupport: 'Contact onze helpdesk alsjeblieft',
      whatIsIdin: 'Uitleg iDIN',
      idinExplanation: `iDIN is een Nederlands online identificatiemiddel. Particulieren kunnen zich met behulp van iDIN identificeren bij een bedrijf zoals {companyName}.
        De persoon logt in bij zijn of haar bank die dan bepaalde gegevens van de persoon, die de bank zelf al gecontroleerd heeft, doorgeeft aan het bedrijf.
        Alle klanten van de bank die al volledig door de bank zijn geïdentificeerd kunnen gebruik maken van iDIN.`,
      idinError: 'Er is een fout opgetreden met de iDIN-service. Probeer het opnieuw of neem contact op met de ondersteuning',
    },
    world: {
      fillWithInfo: 'Persoonlijke informatie',
      uploadYourPassport: 'Upload je identiteitsbewijs (paspoort, identiteitskaart of rijbewijs)',
      submitYourData: 'Verzend gegevens',
    },
    FormStatus: {
      requestSent: 'Aanvraag verzonden!',
      weAreCheckingYourData: 'We controleren je gegevens,<br>we zullen binnen een uur jouw account verifiëren',
      youAreVerified: 'Je bent geverifieerd en klaar om te investeren!',
      exploreProperties: 'Bekijk het vastgoed',
      requestRejected: 'Verzoek afgewezen!',
      weRejectedYourRequest: 'U bent niet als belegger geaccepteerd. <br> Als u denkt dat dit een fout is, neemt u contact met ons op',
    },
    questionnaire: {
      submit: 'Verstuur antwoorden',
      intro: 'Herkomst vermogen',
    },
  },
  properties: {
    exploreProperty: 'Meer details',
    startDate: 'Gestart op',
    noResults: 'We konden geen enkele woning met deze naam vinden, probeer het opnieuw',
    emptyPage: 'Login op het Investor Portal van Rax Finance',
  },
  property: {
    propertyInfo: {
      investMore: 'Investeer meer',
      investOnThisFund: 'Investeer in dit fonds',
    },
    propertyContent: {
      progress: 'Voortgang inschrijvingen',
      investmentCase: 'Samenvatting',
      propertyDetails: 'Over RAX Finance',
      additionalInfo: 'Extra informatie',
    },
  },
  tooltip: {
    amountOwned: 'Het totaal aantal notes dat u in bezit heeft',
    amountGet: 'Het totaal aantal Euro’s dat u elke maand / jaar ontvangt aan rente',
    fundSize: 'Totale waarde van het fonds',
    availability: 'Beschikbaar aantal notes',
    netDividendYieldMine: 'Het Euros dat u per jaar ontvangt',
    netDividendYield: 'De rente die u per jaar ontvangt',
    yourInvestment: 'Het totale bedrag dat u in dit fonds heeft belegd',
    emissionCosts: 'De vergoeding die wordt gerekend om een ​​aandeel te kopen',
    fundProgress: 'Percentage van het fonds dat is volgeschreven',
    onlinePayment: 'Deze betaling is via het platform uitgevoerd',
    offlinePayment: 'Deze betaling is buiten het platform uitgevoerd',
    downloadStatement: 'Selecteer het jaar aan de linkerkant en klik om het jaaroverzicht naar keuze te downloaden',
    initialLogin: 'Klik hier om je dashboard en je investeringsoverzicht te zien',
  },
  gdpr: {
    bar: {
      title: 'Control the collection and use of your personal data',
      textMain: `With your consent, our partners and we use cookies or equivalents to collect, store and use some of
      your personal data, such as your visit on this website. They are used to analyse audiences, offer personalised content
      and / or targeted advertising campaigns, improve customer knowledge, optimize services and fight fraud.
      Click on the “Accept all” button to consent to these uses or click on “Set your options” to obtain more details
      and / or reject all or part of them.`,
      textWarning: `Warning: By continuing your browsing on this site, you accept these uses. You can change your
      mind at any time.`,
      setOptions: 'Set your options',
      acceptEverything: 'Accept everything',
    },
    modal: {
      headerTitle: 'Onze Data Collection Policy',
      /* eslint-disable max-len */
      headerText: '{companyName} maakt op deze site gebruik van cookies. Een cookie is een klein stukje informatie dat in de vorm van een bestand op uw computer of mobiele apparaat wordt opgeslagen. Hierdoor herkennen wij uw computer of mobiele apparaat bij een volgend bezoek.',
      headerTitle2: 'Waarom wij cookies plaatsen',
      headerText2: 'Wij plaatsen cookies om onze site goed te laten werken en te beveiligen. En om onze site, producten en diensten te analyseren en te verbeteren. Door cookies herkennen we u bij een volgend bezoek. Telefoonnummers en e-mailadressen worden niet opgeslagen in cookies. U geeft toestemming voor cookies door op Accepteer te klikken.\n\nWij plaatsen verschillende soorten cookies\nWe kennen vier soorten cookies: Functionele Cookies, Analytische Cookies, Advertentie en personalisatiecookies. We leggen u graag uit op welke manier wij deze verschillende soorten cookies gebruiken.',
      bodyButtons: 'Selecteer of deselecteer welke gegevens u met ons wilt delen',
      bodyButtonsDeselect: 'Alles deselecteren',
      bodyButtonsSelect: 'Alles selecteren',
      bodySeeMore: 'Meer info',
      /* eslint-enable max-len */
    },
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Uitchecken',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Gegevens',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Woordenlijst',
    },
    identification: {
      title: 'Identificatie',
    },
    knowledge: {
      title: 'Kennisbank',
    },
    landing: {
      description: 'De beschrijving van de Landing Page',
    },
    login: {
      title: 'Login',
      description: 'Log in op uw account om te investeren in onze fondsen.',
    },
    properties: {
      title: 'Fondsen',
      description: 'Blader door onze fondsen en ontdek hier meer over hen.',
    },
    property: {
      description: 'Investeer nu in {name}. Deze woning bevindt zich op {address}. U kunt beginnen met beleggen vanaf {min} euros.',
    },
    register: {
      title: 'Registreer',
      description: 'Meld je aan voor {name} en ontdek onze fondsen. Registreer met uw e-mail om te beginnen met beleggen.',
    },
    reset: {
      title: 'Reset wachtwoord',
    },
    settings: {
      title: 'Instellingen',
    },
    verification: {
      title: 'Verificatie',
    },
  },
};
export default {
  nl: mergeWithBrandLangFile(original, 'nl'),
};
