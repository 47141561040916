import deepmerge from 'deepmerge';
import { langFileName } from '../../whitelabel.config';

/**
 * Merging the lang config entries with the specific brand entries,
 * being these second ones high prio
 * @param original
 * @param lang
 */
export const mergeWithBrandLangFile = (original: { [key: string]: any }, lang: string): { [key: string]: any } =>
  deepmerge(original, langFileName ? require(`./${langFileName}`).default[lang] : { });
